<!-- 내 문의사항 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6>손님등록</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card" v-if="pc">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row mb-2">
                <div class="ml-3">행사명 :</div>
                <div class="ml-2">{{ title }}</div>
              </div>

              <div class="row align-items-center">
                <div class="col text-left">
                  <label class="mr-2">개별등록</label>
                  <input
                    type="text"
                    size="10"
                    name="guest_name"
                    placeholder="이름(**필수)"
                    v-model="name"
                  />
                  <input
                    class="ml-2"
                    type="text"
                    size="27"
                    name="guest_phonenumber"
                    placeholder="전화번호(010-0000-0000)(**필수)"
                    v-model="enc_phone_mobile"
                  />
                  <input
                    class="ml-2"
                    type="text"
                    size="23"
                    name="mem_id"
                    placeholder="회원번호"
                    v-model="mem_id"
                  />
                  <base-button
                    type="primary"
                    class="btn-sm ml-2"
                    @click="handleAdd"
                    >등록</base-button
                  >
                  <label class="mr-2"
                    >등록하는 전화번호는 개인정보이므로 본인의 동의를 얻어야
                    합니다.</label
                  >
                </div>
              </div>

              <div class="row align-items-center">
                <div class="lg-3 xs-9 ml-3 mr-1">단체등록</div>
                <label for="file">
                  <div class="lg-3 xs-12 btn-upload">파일 등록</div>
                </label>
                <input
                  type="file"
                  ref="excelUpload1"
                  name="file"
                  id="file"
                  @change="readFile"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
                <div class="col-3 text-left mb-2">
                  <base-button type="primary" class="btn-sm ml-2" @click="write"
                    >명단 엑셀파일 작성 방법</base-button
                  >
                </div>
                <label for="file2" type="warning">
                  <div class="lg-3 xs-12 btn-upload2">엑셀명단 중복체크</div>
                </label>
                <input
                  type="file"
                  ref="excelUpload2"
                  name="file2"
                  id="file2"
                  @change="duplication_check"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
              </div>
              <modal v-model:show="modalWrite" size="md">
                <template v-slot:header>
                  <h6 class="modal-title pl-0">명단 엑셀파일 작성 방법</h6>
                </template>
                ** 엑셀 시트만 업로드 가능합니다. 구글 시트는 업로드 불가능!!<br /><br />
                ** 첫번째 sheet에 작성합니다.<br /><br />
                ** 첫번째 열에는 이름을 입력합니다.<br /><br />
                ** 두번째 열에는 폰번호를 입력합니다(예시 010-0000-0000)<br /><br />
                ** 세번째 열에는 회원번호를 입력합니다<br /><br />
                ** 전화번호 입력시 - 꼭 포함!!!<br /><br />
                <div class="text-red">
                  ** 이외에는 아무것도 적지 않습니다!!!
                </div>
                <br /><br />
              </modal>
              <div class="row align-items-center">
                <div class="col text-left">
                  <label class="mr-2">출입증 문자 발송 </label>
                  <base-button type="info" class="btn-sm ml-2" @click="sendAll"
                    >전체 문자발송</base-button
                  >
                  <base-button type="info" class="btn-sm ml-2" @click="sendAll2"
                    >안 보낸 손님에게만 발송</base-button
                  >
                </div>
                <div class="col text-right">
                  <base-button
                    type="primary"
                    class="btn-sm ml-2"
                    @click="goEntry"
                    >행사목록</base-button
                  >
                </div>
              </div>
              <div class="col-sm-2">
                <preset-select
                  placeholder="sorting"
                  v-model="query.sort"
                  @change="sorting"
                  :choices="[['이름'], ['전화번호']]"
                />
              </div>
            </div>
            <!-- End Search, New Button -->

            <!-- modal -->
            <edit-modal
              ref="editModal"
              @addDone="onAddDone"
              @editDone="onEditDone"
            />
            <!-- List -->

            <el-table
              class="table-responsive table"
              :data="tableData"
              :default-sort="{ prop: 'createdTime', order: 'descending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column label="순서" sortable min-width="50px" prop="no">
              </el-table-column>
              <el-table-column label="이름" sortable width="80px" prop="name">
              </el-table-column>
              <el-table-column
                label="전화번호"
                sortable
                min-width="130px"
                prop="enc_phone_mobile"
              >
              </el-table-column>
              <el-table-column
                label="회원번호"
                sortable
                min-width="130px"
                prop="mem_id"
              >
              </el-table-column>

              <el-table-column
                label="action"
                min-width="130px"
                align="right"
                v-slot="{ row }"
              >
                <div class="d-flex">
                  <base-button
                    class="edit"
                    type="warning"
                    size="sm"
                    @click.stop="handleEdit(row)"
                  >
                    수정
                  </base-button>
                  <base-button
                    class="remove btn-link text-white"
                    type="danger"
                    size="sm"
                    @click.stop="handleDelete(row)"
                  >
                    삭제
                  </base-button>
                </div>
              </el-table-column>
              <el-table-column
                label="발송"
                min-width="130px"
                align="right"
                v-slot="{ row }"
              >
                <div class="d-flex">
                  <base-button
                    v-if="row.sending"
                    class="edit"
                    type="warning"
                    size="sm"
                    @click.stop="resend(row)"
                  >
                    재발송
                  </base-button>
                  <base-button
                    v-else
                    class="edit"
                    type="warning"
                    size="sm"
                    @click.stop="send(row)"
                  >
                    발송
                  </base-button>
                </div>
              </el-table-column>
              <el-table-column
                label="출입일시"
                sortable
                min-width="150px"
                prop="phone_number"
              >
              </el-table-column>
            </el-table>
            <!-- End List -->

            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                @page-change="getDataFromApi"
                :rows-per-page="perPage"
                :total="total"
              />
            </div>
            <!-- End Pagination -->
            <div class="col text-left mb-3 ml-3">
              <input
                type="text"
                size="10"
                name="guest_name"
                placeholder="이름"
                v-model="query.name"
              />

              <!-- New Button -->
              <base-button type="primary" class="btn-sm ml-2" @click="search"
                >찾기</base-button
              >
            </div>
          </div>
          <div class="card" v-else>
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row mb-2">
                <div class="ml-3">행사명 :</div>
                <div class="ml-2">{{ title }}</div>
              </div>

              <div class="align-items-center">
                <label class="col mr-2">개별등록</label>
                <input
                  type="text"
                  size="10"
                  name="guest_name"
                  placeholder="이름(**필수)"
                  v-model="name"
                  class="col"
                />
                <input
                  class="col mt-3"
                  type="text"
                  size="27"
                  name="guest_phonenumber"
                  placeholder="전화번호(010-0000-0000)(**필수)"
                  v-model="enc_phone_mobile"
                />
                <input
                  class="col mt-3"
                  type="text"
                  size="23"
                  name="mem_id"
                  placeholder="회원번호"
                  v-model="mem_id"
                />
                <base-button
                  type="primary"
                  class="btn-sm mt-3 col"
                  @click="handleAdd"
                  >등록</base-button
                >
                <label class="col"
                  >등록하는 전화번호는 개인정보이므로 본인의 동의를 얻어야
                  합니다.</label
                >
              </div>

              <div>
                <div class="col">단체등록</div>
                <label for="file">
                  <div class="col btn-upload ml-4 mt-2">파일 등록</div>
                </label>
                <input
                  type="file"
                  ref="excelUpload1"
                  name="file"
                  id="file"
                  @change="readFile"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
                <div class="col mb-2">
                  <base-button
                    type="primary"
                    class="btn-sm ml-2 mt-3"
                    @click="write"
                    >명단 엑셀파일 작성 방법</base-button
                  >
                </div>
                <label for="file2">
                  <div class="lg-3 xs-12 btn-upload2 ml-4 mt-3">
                    엑셀명단 중복체크
                  </div>
                </label>
                <input
                  type="file"
                  ref="excelUpload2"
                  name="file2"
                  id="file2"
                  @change="duplication_check"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
              </div>
              <modal v-model:show="modalWrite" size="md">
                <template v-slot:header>
                  <h6 class="modal-title pl-0">명단 엑셀파일 작성 방법</h6>
                </template>
                ** 엑셀 시트만 업로드 가능합니다. 구글 시트는 업로드 불가능!!<br /><br />
                ** 첫번째 sheet에 작성합니다.<br /><br />
                ** 첫번째 열에는 이름을 입력합니다.<br /><br />
                ** 두번째 열에는 폰번호를 입력합니다(예시 010-0000-0000)<br /><br />
                ** 세번째 열에는 회원번호를 입력합니다<br /><br />
                ** 전화번호 입력시 - 꼭 포함!!!<br /><br />
                <div class="text-red">
                  ** 이외에는 아무것도 적지 않습니다!!!
                </div>
                <br /><br />
              </modal>
              <div>
                <div class="col">
                  <label class="mr-2 mt-3">출입증 문자 발송 </label>
                  <base-button type="info" class="col btn-sm" @click="sendAll"
                    >전체 문자발송</base-button
                  >
                  <base-button
                    type="info"
                    class="col btn-sm mt-4"
                    @click="sendAll2"
                    >안 보낸 손님에게만 발송</base-button
                  >
                </div>
              </div>
            </div>
            <!-- End Search, New Button -->

            <!-- modal -->
            <edit-modal
              ref="editModal"
              @addDone="onAddDone"
              @editDone="onEditDone"
            />
            <!-- List -->

            <table align="center" width="100%">
              <ul class="list-group list-group-flush list my--3">
                <li
                  v-for="row in tableData"
                  :key="row.id"
                  class="list-group px-0"
                >
                  <tr class="list-group-item">
                    <p>순서: {{ row.no }}</p>

                    <p class="mt-2">이름: {{ row.name }}</p>
                    <p class="mt-2">전화번호: {{ row.enc_phone_mobile }}</p>
                    <p class="mt-2">회원번호: {{ row.mem_id }}</p>

                    <p>
                      <base-button
                        class="edit"
                        type="warning"
                        size="sm"
                        @click.stop="handleEdit(row)"
                      >
                        수정
                      </base-button>
                      <base-button
                        class="remove btn-link text-white ml-4"
                        type="danger"
                        size="sm"
                        @click.stop="handleDelete(row)"
                      >
                        삭제
                      </base-button>

                      <base-button
                        v-if="row.sending"
                        class="remove btn-link text-white ml-4"
                        type="danger"
                        size="sm"
                        @click.stop="resend(row)"
                      >
                        재발송
                      </base-button>
                      <base-button
                        v-else
                        class="remove btn-link text-white ml-4"
                        type="danger"
                        size="sm"
                        @click.stop="send(row)"
                      >
                        발송
                      </base-button>
                    </p>
                    <p class="mt-2">출입일시: {{ row.phone_number }}</p>
                  </tr>
                </li>
              </ul>
            </table>
            <!-- End List -->

            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                @page-change="getDataFromApi"
                :rows-per-page="perPage"
                :total="total"
              />
            </div>
            <!-- End Pagination -->
            <div class="col text-left mb-3 ml-3">
              <input
                type="text"
                size="10"
                name="guest_name"
                placeholder="이름"
                v-model="query.name"
              />

              <!-- New Button -->
              <base-button type="primary" class="btn-sm ml-2" @click="search"
                >찾기</base-button
              >
            </div>
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { ElTable, ElTableColumn } from "element-plus";
import EditModal from "./Board/EditModalGuest.vue";
import Modal from "@/components/Modal";
import * as api from "@/api";
import XLSX from "xlsx";
import PresetSelect from "@/components/Inputs/PresetSelect";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    EditModal,
    Modal,
    PresetSelect,
  },
  // 데이터 객체 추가
  data() {
    return {
      tableData: [], // 레코드셋
      name: "",
      enc_phone_mobile: "",
      mem_id: "",
      query: {
        name: "",
        sort: "",
      },
      perPage: 10, // 페이지당 레코드 수
      total: 0, // 총 레코드 수
      modalWrite: false,
      edit: false,
      excelData: [],
      title: "",
      page2: 1,
      pc: true,
    };
  },
  created() {
    // 페이지 생성 시 호출
    this.getDataFromApi();
  },

  // 함수 추가
  methods: {
    write() {
      this.modalWrite = true;
    },
    // api 호출
    getDataFromApi(page = 1) {
      let a = window.innerWidth;
      let b = window.innerHeight;
      if (a > b && a > 480) {
        this.pc = true;
      } else {
        this.pc = false;
      }
      this.pate2 = page;
      let inv_id = this.$route.query.inv_id;
      const url = `service/myinformation/entry/${this.$route.query.suid}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data2 = response.data;
        const d2 = data2.data;
        this.title = d2.title;
      });
      let sort = "";
      if (this.query.sort) {
        if (this.query.sort == "이름") {
          sort = "name";
        } else if (this.query.sort == "전화번호") {
          sort = "enc_phone_mobile";
        }
      }
      const url2 = `service/myinformation/guest?sort=${sort}&bound=${this.perPage}&page=${page}&inv_id=${inv_id}&name=${this.query.name}`;
      api.get(url2).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        for (const row of d) {
          if (row.pass_id) {
            row.sending = true;
          }
          if (!row.mem_id) {
            row.mem_id = "";
          }
        }
        this.tableData = d;
        this.total = data.total;
      });
    },
    // 등록 모달
    handleAdd() {
      const data = {};
      data.name = this.name.trim();
      data.enc_phone_mobile = this.enc_phone_mobile.trim();
      data.mem_id = this.mem_id;
      data.phone_tail = this.enc_phone_mobile.substr(-4);
      data.inv_id = this.$route.query.inv_id;
      const url2 = `service/myinformation/guest?bound=5&page=1&inv_id=${data.inv_id}&name=${data.name}&enc_phone_mobile=${data.enc_phone_mobile}`;
      api.get(url2).then((response3) => {
        if (!api.isSuccess(response3)) {
          return;
        }
        const data3 = response3.data;
        if (data3.rows > 0) {
          alert("중복 데이타가 존재합니다. 확인부탁드립니다.");
          return;
        }
        api.post("service/myinformation/guest2", { data }).then((response) => {
          data.guest_id = response.data.row;
          api.post("service/myinformation/guest", { data }).then(() => {
            this.name = "";
            this.enc_phone_mobile = "";
            this.mem_id = "";
            this.getDataFromApi(1);
          });
        });
        let inv_id = this.$route.query.suid;
        const url = `service/myinformation/entry/${inv_id}`;
        api.get(url).then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const data2 = response.data;
          const d = data2.data;
          const data = {};
          data.guest_number = d.guest_number + 1;
          api
            .put(`service/myinformation/entry/${inv_id}`, { data })
            .then(() => {});
        });
      });
    },
    goEntry() {
      this.$router.push({
        name: "회관방문자 초대",
      });
    },
    // 수정 모달
    handleEdit(row) {
      this.$refs.editModal.handleEdit(row);
    },
    // 등록 완료
    onAddDone() {
      this.getDataFromApi(1);
    },
    // 수정 완료
    onEditDone(row, data) {
      row.local = data.local;
      this.tableData = this.tableData.slice();
      this.getDataFromApi(1);
    },
    // 삭제하기
    handleDelete(row) {
      if (confirm("삭제하시겠습니까?")) {
        api.delete_(`service/myinformation/guest2/${row.guest_id}`).then(() => {
          api.delete_(`service/myinformation/guest/${row.id}`).then(() => {
            this.tableData = this.tableData.filter((r) => r.id !== row.id);
          });
        });

        let inv_id = this.$route.query.suid;
        const url = `service/myinformation/entry/${inv_id}`;
        api.get(url).then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const data2 = response.data;
          const d = data2.data;
          const data = {};
          data.guest_number = d.guest_number - 1;
          api
            .put(`service/myinformation/entry/${inv_id}`, { data })
            .then(() => {});
        });
      }
    },
    search() {
      this.getDataFromApi();
    },
    sorting() {
      this.getDataFromApi();
    },
    resend(row) {
      const data = {};
      data.pass_id = row.pass_id;
      api
        .get(`service/myinformation/accesswow/${row.pass_id}`, { data })
        .then((response) => {
          if (response.data.msg == "ok") {
            alert("재발송에 성공하였습니다.");
          }
        });
    },
    send(row) {
      let inv_id = this.$route.query.suid;
      const url = `service/myinformation/entry/${inv_id}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data2 = response.data.data;
        const data = {};
        data.expire_dt = data2.expire_dt;
        data.valid_dt = data2.valid_dt;
        data.issue_dt = data2.open_dt;
        data.valid_yn = "Y";
        data.expire_cnt = 1;
        data.target_id = row.guest_id;
        data.enc_phone_mobile = row.enc_phone_mobile;
        data.place_id = data2.place_id;
        data.title = data2.title;
        data.inv_dt = data2.inv_dt;
        data.end_dt = data2.end_dt;
        api
          .post("service/myinformation/accesswow", { data })
          .then((response) => {
            data.pass_id = response.data.data;
            api.post("service/myinformation/access", { data }).then((res) => {
              if (res.data) {
                data.access_id = res.data.data.id;
                api
                  .put(`service/myinformation/accesswow/${row.id}`, { data })
                  .then(() => {
                    alert("발송에 성공하였습니다.");
                    this.getDataFromApi();
                  });
              }
            });
          });
      });
    },
    sendAll() {
      let inv_id = this.$route.query.inv_id;
      const url = `service/myinformation/guest?page=${this.page2}&inv_id=${inv_id}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        this.tableData = d;
        let inv_id2 = this.$route.query.suid;
        let i = 0;
        if (data.rows > 0) {
          for (const row of d) {
            const url2 = `service/myinformation/entry/${inv_id2}`;
            api.get(url2).then((response) => {
              if (!api.isSuccess(response)) {
                return;
              }
              const data2 = response.data.data;
              const data = {};
              data.expire_dt = data2.expire_dt;
              data.valid_dt = data2.valid_dt;
              data.issue_dt = data2.open_dt;
              data.valid_yn = "Y";
              data.expire_cnt = 1;
              data.target_id = row.guest_id;
              data.enc_phone_mobile = row.enc_phone_mobile;
              data.place_id = data2.place_id;
              data.title = data2.title;
              data.inv_dt = data2.inv_dt;
              data.end_dt = data2.end_dt;

              api
                .post("service/myinformation/accesswow", { data })
                .then((response) => {
                  data.pass_id = response.data.data;
                  api
                    .post("service/myinformation/access", { data })
                    .then((res) => {
                      if (res.data) {
                        data.access_id = res.data.data.id;
                        api
                          .put(`service/myinformation/accesswow/${row.id}`, {
                            data,
                          })
                          .then(() => {
                            i++;
                            if (i == d.length) {
                              alert("발송에 성공하였습니다.");
                              this.getDataFromApi();
                            }
                          });
                      }
                    });
                });
            });
          }
        }
      });
    },
    sendAll2() {
      let inv_id = this.$route.query.inv_id;
      const url = `service/myinformation/guest?page=${this.page2}&inv_id=${inv_id}&pass_id=0`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        let inv_id2 = this.$route.query.suid;
        let i = 0;
        if (data.rows == 0) {
          alert("이 페이지에는 발송할 손님이 없습니다.");
        } else {
          for (const row of d) {
            const url2 = `service/myinformation/entry/${inv_id2}`;
            api.get(url2).then((response) => {
              if (!api.isSuccess(response)) {
                return;
              }
              const data2 = response.data.data;
              const data = {};
              // data.expire_dt = data2.expire_dt;
              // data.valid_dt = data2.valid_dt;
              data.inv_dt = data2.inv_dt;
              data.end_dt = data2.end_dt;
              data.valid_yn = "Y";
              data.expire_cnt = 1;
              data.target_id = row.guest_id;
              data.enc_phone_mobile = row.enc_phone_mobile;
              data.place_id = data2.place_id;
              data.title = data2.title;
              api
                .post("service/myinformation/accesswow", { data })
                .then((response) => {
                  data.pass_id = response.data.data;
                  api
                    .post("service/myinformation/access", { data })
                    .then((res) => {
                      if (res.data) {
                        data.access_id = res.data.data.id;
                        api
                          .put(`service/myinformation/accesswow/${row.id}`, {
                            data,
                          })
                          .then(() => {
                            i++;
                            if (i == d.length) {
                              alert("발송에 성공하였습니다.");
                              this.getDataFromApi();
                            }
                          });
                      }
                    });
                });
            });
          }
        }
      });
    },
    readFile(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        // Excel 데이터를 배열에 할당
        this.excelData = jsonData;
        if (this.excelData.length > 0) {
          let i = 0;
          for (const row of this.excelData) {
            i++;
            const data = {};
            data.name = row[0].trim();
            data.enc_phone_mobile = row[1].trim();
            data.mem_id = row[2];
            data.phone_tail = data.enc_phone_mobile.substr(-4);
            data.inv_id = this.$route.query.inv_id;
            api
              .post("service/myinformation/guest2", { data })
              .then((response) => {
                data.guest_id = response.data.row;
                api.post("service/myinformation/guest", { data }).then(() => {
                  this.name = "";
                  this.enc_phone_mobile = "";
                  this.getDataFromApi(1);
                });
              });
            if (i == this.excelData.length) {
              let inv_id = this.$route.query.suid;
              const url = `service/myinformation/entry/${inv_id}`;
              api.get(url).then((response) => {
                if (!api.isSuccess(response)) {
                  return;
                }
                const data2 = response.data;
                const d = data2.data;
                const data = {};
                data.guest_number = d.guest_number + this.excelData.length;
                api
                  .put(`service/myinformation/entry/${inv_id}`, { data })
                  .then(() => {});
              });
            }
          }
        }
      };
      reader.readAsArrayBuffer(file);
      this.$refs.excelUpload1.value = "";
    },
    duplication_check(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        // Excel 데이터를 배열에 할당
        this.excelData = jsonData;
        let data3 = "";
        let compareArray1 = [];
        let compareArray2 = [];
        if (this.excelData.length > 0) {
          let z = 0;
          for (const row of this.excelData) {
            const data = {};
            data.name = row[0].trim();
            data.enc_phone_mobile = row[1].trim();
            data.mem_id = row[2];
            data.phone_tail = data.enc_phone_mobile.substr(-4);
            data.inv_id = this.$route.query.inv_id;
            let compareData = data.name + data.enc_phone_mobile;
            if (compareArray1.includes(compareData)) {
              if (z == 0) {
                data3 = data3 + "엑셀안에서 중복데이타 \n";
                z = 1;
              }
              if (!compareArray2.includes(compareData)) {
                data3 =
                  data3 +
                  "이름: " +
                  data.name +
                  ", 전화번호: " +
                  data.enc_phone_mobile +
                  "\n";
                compareArray2.push(compareData);
              }
            } else {
              compareArray1.push(compareData);
            }
          }
        }
        if (this.excelData.length > 0) {
          let i = 1;
          let t = 0;
          for (const row of this.excelData) {
            const data = {};
            data.name = row[0];
            data.enc_phone_mobile = row[1];
            data.mem_id = row[2];
            data.phone_tail = data.enc_phone_mobile.substr(-4);
            data.inv_id = this.$route.query.inv_id;
            const url3 = `service/myinformation/guest?bound=5&page=1&inv_id=${data.inv_id}&name=${data.name}&enc_phone_mobile=${data.enc_phone_mobile}`;
            api.get(url3).then((response) => {
              if (!api.isSuccess(response)) {
                return;
              }
              const data2 = response.data;
              console.log("d3" + JSON.stringify(data2));
              if (data2.rows > 0) {
                if (t == 0) {
                  data3 = data3 + "\n디비와 엑셀간 중복 데이타 \n";
                  t = 1;
                }
                data3 =
                  data3 +
                  "이름: " +
                  data.name +
                  ", 전화번호: " +
                  data.enc_phone_mobile +
                  "\n";
              }
              if (i == this.excelData.length) {
                if (data3 == "") {
                  alert("중복데이타가 존재하지 않습니다.");
                } else {
                  alert("중복데이타가 존재합니다. \n\n" + data3);
                }
              }
              i++;
            });
          }
        }
      };
      reader.readAsArrayBuffer(file);
      this.$refs.excelUpload2.value = "";
    },
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.btn-upload {
  width: 150px;
  height: 30px;
  background: #53b136;
  border: 1px solid rgb(77, 77, 77);
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: rgb(77, 77, 77);
    color: #fff;
  }
}

.btn-upload2 {
  width: 150px;
  height: 30px;
  background: #d4d121;
  border: 1px solid rgb(77, 77, 77);
  border-radius: 2px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: rgb(77, 77, 77);
    color: #fff;
  }
}

#file {
  display: none;
}
#file2 {
  display: none;
}
</style>
